<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
      <el-breadcrumb-item>系统用户</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card shadow="never">
      <div class="change">
        <el-input v-model="queryInfo.realName"
                  clearable
                  placeholder="请输入用户名称"></el-input>
        <el-input v-model="queryInfo.phone"
                  clearable
                  placeholder="请输入用户电话"></el-input>
        <el-select placeholder="请选择角色"
                   v-model="queryInfo.roleFlag"
                   clearable
                   @focus="changeRole()">
          <el-option v-for="item in roleList"
                     :key="item.id"
                     :label="item.roleName"
                     :value="item.id">
          </el-option>
        </el-select>
        <el-button type="primary"
                   @click="search">查询</el-button>
        <el-button>导出</el-button>
        <el-button type="primary"
                   class="add"
                   @click="addDialogVisible = true">新增</el-button>
      </div>

      <!-- 用户信息框 -->
      <el-table :data="userList"
                border
                stripe
                style="width: 100%"
                :header-cell-style="{background:'rgba(22, 119, 255, 0.0300)'}">
        <el-table-column type="index"
                         align="center"></el-table-column>

        <el-table-column prop="realName"
                         label="姓名"
                         width=""
                         align="center">
        </el-table-column>
        <el-table-column prop="phone"
                         label="手机号"
                         width="175"
                         align="center">
        </el-table-column>
        <el-table-column prop="account"
                         label="用户账户"
                         width=""
                         align="center">
        </el-table-column>
        <el-table-column prop="roleFlag"
                         label="角色"
                         width=""
                         align="center">
          <template slot-scope="scope">{{map[scope.row.roleFlag]}}</template>
        </el-table-column>
        <el-table-column prop="flag"
                         label="状态"
                         width="154"
                         align="center">
          <template slot-scope="scope">{{map1[scope.row.flag]}}</template>
        </el-table-column>
        <el-table-column label="操作"
                         width="275"
                         align="center">
          <template slot-scope="scope">
            <el-button type="warning"
                       size="mini"
                       icon="el-icon-edit"
                       @click="edit(scope.row.id)">编辑</el-button>
            <el-button type="danger"
                       size="mini"
                       icon="el-icon-delete"
                       @click="removeUser(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="queryInfo.current"
                     :page-sizes="[10,20,50,100,200]"
                     :page-size="queryInfo.size"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>
    <!-- 添加用户对话框 -->
    <el-dialog title="新增系统用户"
               :visible.sync="addDialogVisible"
               width="40%"
               @close="closeDialog">
      <el-form ref="addRuleRef"
               :model="addForm"
               label-width="80px"
               :rules="addFormRules">
        <el-form-item label="用户姓名"
                      prop="realName">
          <el-input v-model="addForm.realName"
                    clearable></el-input>
        </el-form-item>
        <!-- <el-form-item label="用户账户"
                      prop="account">
          <el-input v-model="addForm.account"
                    clearable></el-input>
        </el-form-item> -->
        <el-form-item label="手机号"
                      prop="phone">
          <el-input v-model="addForm.phone"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="用户角色"
                      prop="roleFlag">
          <el-select placeholder="请选择角色"
                     v-model="addForm.roleFlag"
                     @focus="changeRole()">
            <el-option v-for="item in roleList"
                       :key="item.id"
                       :label="item.roleName"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="addUser">保 存</el-button>
        <el-button @click="addDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 编辑用户对话框 -->
    <el-dialog title="修改系统用户"
               :visible.sync="editDialogVisible"
               width="40%"
               @close="editCloseDialog">
      <el-form ref="editFormRef"
               :model="editForm"
               :rules="editFormRules"
               label-width="80px">
        <el-form-item label="用户姓名"
                      prop="realName">
          <el-input v-model="editForm.realName"
                    clearable
                    placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号"
                      prop="phone">
          <el-input v-model="editForm.phone"
                    clearable
                    placeholder="请输入手机号"></el-input>
        </el-form-item>
        <!-- <el-form-item label="用户账户"
                      prop="account">
          <el-input v-model="editForm.account"
                    clearable
                    placeholder="请输入账户"></el-input>
        </el-form-item> -->
        <el-form-item label="状态"
                      prop="flag">
          <el-select placeholder="请选择状态"
                     v-model="editForm.flag">
            <el-option v-for="item in map2"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户角色"
                      prop="roleFlag">
          <el-select placeholder="请选择角色"
                     v-model="editForm.roleFlag"
                     @focus="changeRole()">
            <el-option v-for="item in roleList"
                       :key="item.id"
                       :label="item.roleName"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="editUser">保 存</el-button>
        <el-button @click="editDialogVisible = false">取 消</el-button>
      </span>

    </el-dialog>
  </div>
</template>
      <script>
export default {
  data() {
    return {
      // 控制添加系统用户对话框的显示与隐藏
      addDialogVisible: false,
      // 控制添加用户对话框的显示与隐藏
      editDialogVisible: false,
      // 获取系统用户列表
      userList: [],
      queryInfo: {
        id: '',
        siteName: '',
        phone: '',
        realName: '',
        current: 1,
        size: 10,
        flag: '',
      },
      total: 0,
      // 添加用户表单数据
      addForm: {
        id: '',
        realName: '',
        phone: '',
        roleFlag: '',
        flag: '',
        account: '',
        siteNum: '',
        siteName: '',
        type: 'sys',
      },
      // 添加表单的验证规则对象
      addFormRules: {
        realName: [
          { required: true, message: '请输入用户姓名', trigger: 'blur' },
        ],
        account: [
          { required: true, message: '请输入用户账户', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: '请输入正确的手机号码',
          },
        ],
        roleFlag: [
          { required: true, message: '请选择用户角色', trigger: 'change' },
        ],
      },
      // 编辑表单的验证规则对象
      editFormRules: {
        realName: [
          { required: true, message: '请输入用户姓名', trigger: 'blur' },
        ],
        account: [
          { required: true, message: '请输入用户账户', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: '请输入正确的手机号码',
          },
        ],
        flag: [
          { required: true, message: '请选择用户状态', trigger: 'change' },
        ],
        roleFlag: [
          { required: true, message: '请选择用户角色', trigger: 'change' },
        ],
      },
      // 修改用户表单数据
      editForm: {},
      //  角色列表选择器
      roleList: [],

      // 状态选择器
      flagList: [],
      map: [
        '',
        '超级管理员',
        '一级管理员',
        '运营人员',
        '站长',
        '分拣员',
        '配送员',
      ],
      map1: ['禁用', '启用'],
      map2: [
        {
          value: 1,
          label: '启用',
        },
        {
          value: 0,
          label: '禁用',
        },
      ],
    }
  },
  created() {
    this.getUserList()
    this.changeRole()
    // this.changeFlag()
  },
  methods: {
    // 获取系统用户列表
    getUserList() {
      this.axios.post('/api/site/sysUser/page', this.queryInfo).then((res) => {
        // console.log(res)
        this.userList = res.data.data.records
        this.total = res.data.data.total
      })
    },
    // 搜索框
    search() {
      this.queryInfo.current = 1
      this.getUserList
    },
    // 监听一页有几条数据
    handleSizeChange(newSize) {
      // console.log(newSize);
      this.queryInfo.size = newSize
      this.getUserList()
    },
    // 监听第几页
    handleCurrentChange(newPage) {
      // console.log(newPage);
      this.queryInfo.current = newPage
      this.getUserList()
    },
    // 点击获取角色选择器列表
    changeRole() {
      this.axios
        .get(`/api/site/role/getRoleList?type=${this.addForm.type}`)
        .then((res) => {
          //   console.log(res)
          this.roleList = res.data.data
        })
    },
    // 点击获取状态选择器
    changeFlag() {
      this.axios
        .get(`/api/site/sysUser/setStatus?id=${this.editForm.id}`)
        .then((res) => {
          console.log(res)
          this.flagList = res.data.data
        })
    },
    // 点击添加系统新用户
    addUser() {
      this.$refs.addRuleRef.validate((valid) => {
        // console.log(valid)
        if (!valid) return
        this.axios.post('/api/site/sysUser/add', this.addForm).then((res) => {
          //   console.log(res)
          this.$message({
            message: '添加成功',
            type: 'success',
            duration: 1000,
          })
          // 重新获取用户列表
          this.getUserList()
          // 点击确认关闭
          this.addDialogVisible = false
        })
      })
    },
    // 监听添加用户对话框关闭重置
    closeDialog() {
      this.$refs.addRuleRef.resetFields()
    },
    // 编辑用户信息
    edit(id) {
      this.editDialogVisible = true
      //   console.log(id)
      this.axios.get(`/api/site/sysUser/getUserInfo?id=${id}`).then((res) => {
        // console.log(res)
        this.editForm = res.data.data
        this.editForm.flag = +res.data.data.flag
        // console.log(this.editForm.flag)
      })
    },

    // 监听修改用户对话框关闭重置
    editCloseDialog() {
      this.$refs.editFormRef.resetFields()
    },
    // 点击修改用户
    editUser() {
      this.$refs.editFormRef.validate((valid) => {
        if (!valid) return
        // console.log(valid)
        this.axios
          .post('/api/site/sysUser/editUser', this.editForm)
          .then((res) => {
            // console.log(res)
            this.$message({
              message: '修改成功',
              type: 'success',
              duration: 1000,
            })
            // 重新获取用户列表
            this.getUserList()
            // 点击确认关闭
            this.editDialogVisible = false
          })
      })
    },
    // 根据id删除对应用户信息
    removeUser(id) {
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.axios.get(`/api/site/sysUser/delUser?id=${id}`)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.getUserList()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
  },
  watch: {
    queryInfo: {
      handler: function (newVal) {
        // console.log(newVal.queryInfo)
        this.getUserList()
      },
      deep: true,
    },
  },
}
</script>
      <style scoped lang="less">
.el-breadcrumb {
  margin-bottom: 20px;
}
.el-table {
  margin-top: 20px;
}
.el-input {
  margin-right: 8px;
  width: 240px;
}
.el-card {
  margin-bottom: 20px;
}

.change {
  margin: 20px 0;
  .el-select {
    margin-right: 20px;
  }
}

.el-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.add {
  margin-left: 50px;
}
</style>
      
    
